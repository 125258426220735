<template>
    <el-dialog
        title="提交申请"
        :visible.sync="editApplicationSubmitVisible"
        width="30%"
        :before-close="handleClose">
        <div class="content">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="活动名称">
                    <el-input type="textarea" v-model="form.name"></el-input>
                </el-form-item>
                <div>确定提交{{'变更','停发'}}申请吗？</div>
                <div style="margin-top: 10px">审核通过后，次月停止相关服务</div>
            </el-form>
        </div>
        <div class="content">
            未配置XXXX的补贴审批流程，请联系管理员进行配置！
        </div>
        <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
     <el-button type="primary" @click="handleClose">知道了</el-button>
  </span>
    </el-dialog>
</template>

<script>
  export default {
    name: "editApplicationSubmit",
    props: {
      editApplicationSubmitVisible: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        form: {}
      }
    },
    methods: {
      handleClose() {
        this.$emit('update:editApplicationSubmitVisible', false)
      }
    }
  }
</script>

<style scoped>
    .content {
        padding: 20px 50px;
    }
</style>
